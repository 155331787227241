@import url(https://fonts.googleapis.com/css?family=Montserrat:900|Raleway:400,400i,700,700i);

html,body,a
{
	font-family: 'Montserrat';font-size:16px;
}
.sizes{
    font-size: 10px;
}
.text_size
{
	font-size: 20px;
    font-weight: bolder;
	color: #257107;
	width: auto;
    margin: auto;
}

.apply_label
{
	font-size: 22px;
    font-weight: bolder;
	color: #257107;
	max-width: 140px;
	padding-right: 15px;
}
.apply_div{

	text-align: center;
    font-size: 28px;
    max-width: 140px;
}

.input_text_size
{
	color: rgb(37, 113, 7);
	font-weight: bolder;
    text-align: center;
    font-size: 28px;
    max-width: 250px;
}
Button #view_btn
{
	margin-top: 0;
	width: fit-content !important
}
.main_div
{
	max-width: 1250px;
}
.icon-menu{
	width: 1000px;
}
.home-menu{
	margin-top: 80px;

}
.home-menu-r{
	margin-top: 100px;
	/* margin: 150px 50px; */

}
.home-menu h3 
{
	color:#53b7d8;
}
.home-text h4 
{
	padding-top:10px;
	text-align: center;
	margin-top : 30px;
}

.home-menu .icon
{
	font-size: xx-large;
}
.home-menu,.home-menu-r ul
{
	list-style-type:none;
}

.home-menu-r a{
	font-size: 19px;
	letter-spacing: 1px;
}

.home-menu,.home-menu-r ul li {
   
  padding: 12px 0;
  
}
.home-menu a 
{
	font-size: 19px;
	letter-spacing: 1px;
	margin: 10px;

}
.kyc-container{
	width: 1000px;
	margin: auto;
	
}
.kyc_heading{
	margin-top:25px;
}
.kyc_rule{
	font-size: 18px;
	margin: 5px -50px 15px;
}
.toggle_switch{
	transform: scale(2);
	margin-top: 5px;
}
.instant_disbursal {
	color:black;
}
.custom-control-input:checked~.custom-control-label::before {
	color: #fff;
	 background-color:green;
   outline: 0;
   -webkit-box-shadow: none;
   box-shadow:green;
   border:green solid 1px;
  }
/* .custom-switch .custom-control-label::before{
	background-color: #28a745 !important;
	border-color: #28a745 !important;
	color: #fff !important;
} */
.home_pend_approvals
{
	width: 1000px;
    margin: auto;
}
.home_stalled_fas
{
	width: 85%;
    margin: auto;
}

.cust_view_elements li {
	display: inline-block;
	margin-left: 20px;
}
.cust_view_elements{
	margin-left: -70px;
}

.vl {
  border-left: 2px solid black;
  height: 20px;
  display: flex;
  align-items: center; 
}

.badge_style
{
	min-width: 60px;
	color: #fff;
    
}
.model_window .modal-dialog{
	max-width:100%;
	/* height: 100% !important; */
	margin:0;


}

.custom-map-modal .modal-dialog {
    width: 100%;
    height: 100%;
    
  }
  .modal-content {
  	 width: 100%;
    height: 100%;
    
   
    border-radius: 0;
 }


.image_dimen{
	max-width:300px;
	max-height:300px;
}
.model_window img {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

div[disabled=disabled] {
  pointer-events: none;
  opacity: 0.4;
}

.bg-dark{
	/*background-color: #0a0a0a!important;*/
    background-color: #01062c!important;
}
.apply_fa
{
color: #92A9F4 !important;
}
.search_loans
{
 color: #92A9F4 !important;
}
.logout
{
 color: #92A9F4 !important;
}
.modify
{
	background: #FA4616;
}
.btn-danger{
	background: #FA4616 !important;
	border-color: #FA4616 !important;
}

.badge-orange{
	background:#f4623b !important;
	border-color: #f4623b !important;
	color: white !important;
}

.flow_custid
{
	color: #3F63F4;
}
.apply
{
	color: #3F63F4;
}

.disable_div{
 pointer-events: none;
 opacity: 0.45; 
}

.enable_div{
 pointer-events: pointer;
 opacity: 100; 

}
.btn-primary
{
  color: white !important;
  background: #3F63F4 !important;
  border-color: #3F63F4 !important;
}
.btn-retry
{
  color: white !important;
  background: #3F63F4 !important;
  border-color: #202940 !important;
}
#red_color{
	color: red;
}

.lowerCase{
	text-transform: lowercase;
}

.upperCase{
	text-transform: uppercase;
}

.reset_popover_style{
	padding:5px 0;
	font-size : 13px !important;
	width:100%;
}

.show_button{
	display: inline;
	disabled: false;
}

.show_div{

    display: inline;
	
}

.hide_div{
	display: none;
	
}
.disable_button{
	display: inline;
	disabled: true;
}


.hide_button{
	display: none !important;
	disabled: false;

}

#datatable{
	margin-left: 113px;
	padding-top: 10px;
	width:70%;
}
.comment


td
{
    position: absolute;
    bottom: -14px;
}


td
{
	white-space:nowrap
}

.acc-stmt-table.dataTable tbody td {
     padding: 5px 10px !important; 

}

table.dataTable tbody td {
    padding: 1px 10px !important;
}
table.dataTable tbody tr 
{
	height: 10px !important;
}

#view_button{
	line-height:2em;padding-top:0px ;padding-bottom: 0px ;margin-top: 5px;
}

#center_align{
	margin-left: 500px;
}

.form-text{
	position:relative;
}

.static-value{
	position: absolute;
	left:10px;
	font-weight: bold;
	/* font-size:0.8em; */
	top:9px;
	right:10px;
}

.input-group{
	position: relative;
	display: table;
	border-collapse: separate;
}
.input-group-addon.prefix{
	border-radius: 4px 0px 0px 4px;
	border-right: 0;
}

.input-group-addon{
	padding: 6px 12px;
	font-size: 14px;
	font-weight: 400px;
	line-height: 1;
	text-align: center;
	border:1px solid #ccc;
	width:1%;
	white-space: nowrap;
	vertical-align: middle;
	display: table-cell;
}

.input-style{
	position: relative;
	z-index: 2;
	float: left;
	width:100%;
	margin-bottom: 0px;
	display: table-cell;
	padding: 6px 12px;
	border:1px solid #ccc;
	margin: 0;

}

.prefix .input-group-addon{
	border-right: 0px;
	border-radius: 4px 0px 0px 4px;
}
.center{
	padding-right: 1%;
}

.center_btn{
	padding-right: 30%;
}
#float_style{
	float: right !important;
}

#vertical_line{
  border-left: 2px solid black;
 
}

#spacing_style{
	width: 20px;
    height: 5px;
    margin: 10px;
	display:inline-block;
}

.red_color_font{
	color:red;
}

.btn_style{

	margin:10px;
}



.modal-dialog-style{
	width:250% !important;
	max-width: 600px !important;
}
.modal-dialog-logo
{
	width:60% !important;
	max-width: 200px !important;
	height: 100px;
}
.modal-dialog-img_size
{
    width:200% !important;
	max-width: 400px !important;
	height: 500px;
   /*  max-width: 250%;
	height: 500px;
	margin-right: auto;
	margin-left: 20px;
	display: block;*/
}
.modal-dialog-reduce{
	width: 50% !important;

}

.modal-dialog-width{
	width: 300px !important;


}

.modal-dialog-sign{
	position: fixed;
	width:100% !important;
	max-width: 100%;
	
}

.signature_style{
	
	border-style: solid;
	border-color: #A9A9A9;
	width: 250px;
	height: 125px;	
	/* margin-left: 40%; */

}

.sig_container{
	width:80%;
	height:80%;
	margin:0 auto;
}

.container{
	width:100%;
	top:10%;
	left:5%;
	
}

.modal-body-style{
	width: 100%;
	padding: 30px;
	position: relative;
}

.modal-dialog-img{
	width: 100%;

}

.account
{
	float: right;
}

.h3, h3 {

    font-size: 1.25rem !important;
}

.h2, h2 {
	
         font-size: 1.75rem !important;
         color:red ;
}

h1, h2, h3, h4, h5, h6 , .MuiAccordionSummary-root  h4{
	font-family: Montserrat;
}

.btn-logout {
	width: 100%;
	padding: 3px 20px;
	text-align: left;
}

.modal-dialog-brwr{
	width:1000px !important;
}


.img_size{
	max-width: 250%;
	height: 500px;
	margin-right: auto;
	margin-left: 20px;
	display: block;
}

.img_style{

	margin-left: 30%;
}


.reset
{
	margin-left: 15px !important;
}


table.dataTable thead th, table.dataTable thead td tr {
     padding: 0px 0px;

    border-bottom: 1px solid #111111;
}

table.dataTable tbody tr {
	height: 30px;
}

.spacing
{
	letter-spacing: 5px;
	font-weight : bold;
}

.link-doc-id{
	color: #c78cd1;
}

.addcomment
{
	position: absolute;
    top: 17px;
    left: 159px;
}
.disburse
{
	float: right;
}
.right_align
{
	text-align: right;
}

.left_align
{
	width: 20%;
}

.fa_amount
{
	width: 80% !important;

}


table.dataTable thead tr th 
{   
	 text-align: center !important;  
}

.list
{
	width: 100% !important;
}
.fa_left
{
	margin-left: 15px !important;
}


.custom-modal{
	max-width: 200px !important;
}


.text_color{
	color: white;

}

table.dataTable thead th {
	text-align: center !important;
}
.title
{
	margin-left: 10px;
}
.right-align{
	float: right;
}
.agreement
{
	padding-left:5% !important;
}

.agreement_disable{
	padding-left:130px !important;
	cursor: default;
	pointer-events: none;
}

.text_box_style{
	width: 25%;
	/* vertical-align: middle; */
	text-align: right;
}

.width-20{
	width: 20%;
}

.list_weightage{
	width: 80%;
}

.width-25{
	width: 25%;
}

.width-50{
	width:50%;
}
.width-15{
	width:15%;
}
.width-30{
	width:30%;
}

.weightage_style{
	width:50%;
	float:right;
	text-align: right;
}
.width-10
{
	margin: 5px -28px;
}

.pull-right{
	float: right;
}
.pull-left{
	float: left;
}


.border_style{
	border-style: groove;
	/* border-width: medium; */
	/* width:1000px;
	height:300px; */
	/* background-color: #c2eef2; */
}

.panel_border{
	border-style: groove;	
	width:1100px;
	height:auto;
	padding:10px;

}

.aggr_style{
	width: 100%;
	height: 750px;
}


.borrower_table{
    margin-left: -16px !important;
    padding-top: 10px;
    width: 100%;
}


.establishment_head
{
 margin-left: 30%;
}
.photo_head
{
  margin-left: 40%;
}
#report,.data,.head
{
  border: 1px solid black;
  border-collapse: collapse;

}


.report_title
{
	font-weight: bold;
	background-color :#92A9F4;
}
.date-right
{
	text-align: right !important;
}
#kpi_table {
	--bs-table-bg: #1a2035 !important;
}
#kpi_table th 
{   
	 text-align: center !important;  
	 background-color : #92A9F4;
	
}
.dt-empty{
	display: none !important;
}

#kpi_table td
{   
	 
	 padding-left: 8px !important;
}

#kpi_table tr:nth-child(even) {background-color : #E3EBFC;}

.borrower_left
{
	/*margin-left:35px;*/
}

table.flow-data-table thead tr th 
{
	border-right: 1px solid #bfb9b9;
	border-bottom:none;
	padding-left: 1px !important;
	padding-right: 1px !important;
}

table.flow-data-table-wide_header thead tr th 
{
	
	padding-left: 5px !important;
	padding-right: 5px !important;
}


/*table.pretty thead th{
	background-color: #ccd1c9 !important;
}
table.pretty tbody tr.odd td{
	background-color: #cfd4cb !important;
}
table.pretty tbody tr.even td{
	background-color: #eef2eb !important;
}*/

 /*#tooltip-top > .tooltip-inner {
 	background-color: white !important;
	color: black !important;
 }

 .bs-tooltip-top .arrow::before {
 	border-top-color: white !important;
 }*/
 .establishment
 {
 	width: 600px;
 	margin:150px;
 }
 .photo
 {
 	width: 450px;
 	margin: 230px;
 }
.serach_loan_appl
{
	padding-left: 15px;
}
.search_loan
{
	padding-left: 15px;
}
#data_prvdr_table
{
	margin-left: -2px;
}
#product_temp
{
	margin-left: -2px;
}
.cust_font
{
	font-size: 13px;
}
.cust_details
{
	color: green;
}
table.lender_list thead tr th 
{
	border-right: 1px solid #bfb9b9;
	border-bottom:none;
	padding-left: 3px !important;
	padding-right: 3px !important;
}


table#list_product thead tr th 
{
	border-right: 1px solid #bfb9b9;
	border-bottom:none;
	padding-left: 5px !important;
	padding-right: 5px !important;
}
.fa_product
{
	margin-left: -18px;
}
.on_going
{
	color: #e342ff;;
}
.overdue
{
	color: #FA4616;
}
.pending
{
	color: #00CE70;
}

.hide
{
	display: none !important;
}
.enabled
{
	color: green;
}
.do_enable
{
	background: #3F63F4 !important;
	color: white  !important;
}
.do_enable:hover
{
	color: white;
}
.bg-dark ul li .dropdown-toggle
{
 color: #92A9F4 !important;
}
.bg-dark ul li .dropdown-toggle:hover
{
	color: white !important;
}
.bg-dark ul li .apply_fa:hover
{
	color: white !important;
}
.modify
{
	color: white !important;
}
.top
{
	margin-top: 10px !important;
}
.chart
{
	margin-left: 125px !important;
	margin-top: 20px !important;
}
table.dataTable tbody td 
{
	text-align: center !important; 
}

.modal_ul{
	text-decoration: none;
}

.flag_cursor{
	cursor:pointer;
}

.popover, .popover span a{
   font-size: 15px;
 }


table.dataTable tbody td .badge
{
	width: 60px !important;
	height: 20px !important;
	padding-top: 5px !important;
}
.account_txn_type
{
	margin-left: 50px !important;
}

.account_left
{
	margin-left: 13px !important;
}

.account_prvdr
{
	padding: 0px !important;
}
.view_loan
{
	margin: -95px 30px !important;

}
.menu_ul{
	list-style-type:none;
}
.logo_style{
	color:white;

}
.loan_apply
{
	margin-left: 12px !important;
}

table.cust-rep thead th, table.cust-rep thead td{
	padding: 10px 18px !important;
}


/*added for instant disbursal*/
.reset-padding{
  padding: 0!important;
}
.reset-margin{
  margin:0 !important;
}
/*.vertical-alignment-helper {
    display: table;
    height: 100%;
    width: 100%;
    pointer-events: none;
}
.modal-medium-width {
    max-width: 540px;
    width: 100%;
    height: 480px;
}
.modal-medium-width .modal-content{
    width: inherit;
    max-width: inherit;
    height: inherit;
    margin: 0 auto;
    pointer-events: all;
}
.vertical-align-center {
    display: table-cell;
    vertical-align: middle;
    pointer-events: none;
}

.pf-rule-modal .modal-header {
    padding: 33px 0 21px 0;
    margin-left: 34px;
    margin-right: 19px;
    border-bottom: 1px solid #E2E1E6;
}
.pf-rule-modal .modal-footer {
    padding: 14px 0 14px 0;
    margin-left: 34px;
    margin-right: 19px;
    border-top: 1px solid #E2E1E6;
}
.pf-rule-modal .modal-header h2 {
    font-size: 20px;
    color: #3B3BAA;
    line-height: 23px;
    margin: 0;
    font-weight:700;
}
.rule-modal-body.modal-body{
  padding-top: 20px!important;
  margin-left: 34px;
  margin-right: 19px;
  height: 351px;
}
.rule-modal-body .rule-form-group{
  margin-top:8px!important;
}
.rule-modal-body .rule-form-group:first-child{
  margin-top:0!important;
}
.rule-form-group label.label__name{
  margin-bottom:.5rem;
  line-height: 2.2;
}
.rule-form-group label.label__name i{
  font-size: 21px;
  color: #3B3BAA;
  margin-right:9px;
}
button.pf-btn{
  display: none;
}
button.btn-primary{
  background: #3B3BAA;
  border: 1px solid #3B3BAA;
  border-radius: 4px;
  box-shadow: none;
}
button.btn-primary:hover{
  background: #3B3BAA;
  border: 1px solid #3B3BAA;
}
button.btn-outline-primary{
  color: #3B3BAA;
  border-color: #3B3BAA;
}
button.btn-outline-primary:hover{
  background: #3B3BAA;
  border: 1px solid #3B3BAA;
}
.pf-rule-modal .modal-footer button.btn-outline-primary{
  padding: 0;
  width: 87px;
  height: 32px;
}
.pf-rule-modal .modal-footer button.btn-primary {
    padding: 0;
    width: 73px;
    height: 32px;
}

*/

@keyframes rotate {
	from {transform: rotate(0deg)}
	to {transform: rotate(360deg)}
}

.rotate-icon{
	animation-name: rotate;
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-timing-function: ease;
	animation-play-state: running;
}

label.select_toogle ,input.select_toogle{
    font-size:.875rem;
    width:262px;
    padding: 7px 12px 9px 12px;
    border: 1px solid #E2E1E6;
    border-radius: 4px;
    
}
.reset_width{
    width:  100% !important;
}

.reset_font_size{
	font-size: 1rem !important;
}
.reset_border{
	border : 0 !important;
	cursor: none;
	padding: 0 0 9px 2px !important;
}
label.select_toogle:hover{
    cursor:pointer;
}
.toggle:checked ~ label.select_toogle {
    /* border:3px solid #48a868; */
    /* font-weight: bold; */
   
}

.rec-title-right{
	position:absolute;
	right:40px;
}

.rec-form-modal{
	max-width:65% !important;
	position: relative;
}

.rec-form > .row{
	margin-bottom: 3%;
}

.rec-form > .row:last-child{
	margin-bottom: 0;
}

.visually-hidden {
    position: absolute;
    left: -100vw;

    /* Note, you may want to position the checkbox over top the label and set the opacity to zero instead. It can be better for accessibilty on some touch devices for discoverability. */
}

.txn_id_style{
	pointer-events: none;
}
.p-inherit{
	padding: inherit
}
.closed-stamp {
	position: relative;
    top: -100px;
    left: -100px;
    z-index: 1;
    pointer-events: none;
    border-top: 140px solid transparent;
    border-bottom: 140px solid transparent;
    transform: rotate(45deg);
    border-right: 140px solid rgb(238 8 8 / 46%);
}
.text {
	color: white;
    font-size: 25px;
    font-weight: bolder;
    position: absolute;
    width: 175px;
    height: 11px;
    top: 100%;
    left: 300%;
    transform: rotate(270deg);
    text-align: center;
  }

.pointer_evt{

	pointer-events: none;
}


.arrow-left {
	position: relative;
    top: -106px;
    left: -64px;
    z-index: 1;
    pointer-events: none;
    border-top: 140px solid transparent;
    border-bottom: 140px solid transparent;
    transform: rotate(45deg);
    border-right: 140px solid rgb(238 8 8 / 75%);
	opacity: 50%;

}

/* .text {
	color: white;
    font-size: 25px;
    font-weight: bolder;
    position: absolute;
    width: 175px;
    height: 11px;
    top: 100%;
    left: 300%;
    transform: rotate(270deg);
    text-align: center;
  } */

.writeoff-text{
	text-align : center;
	font-size : 28px;
	color :white;
}

.writeoff-text div{
	padding: 20px;

}
.acc_elig{
	font-size: larger;

}
@media (max-width: 1848px){
	.view_loan_menu{

	 }
}

@media (min-width: 1200px){
.container {
    max-width: 98% !important;
}
}

.align_ver_center{
	display: flex;
	align-items: center;
}
.rdtPicker{
	width: 100%!important;
}
.bordered_green{
	border: 3px solid #48a868!important;
    font-weight: bold;
}
.h-40{
	height: 40px;
}
.text-green{
	color:#48a868!important;
}
.text-red{
	color:red!important;
}
.overdue_fontBold{
	font-weight: 700;
}
.excess_fieldBlinker_box{	
	height: 48px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 24px red;
    border: solid 4px red;    
    border-radius: 4px;
    -webkit-animation: blinker 1s linear infinite;
    animation: blinker 1s linear infinite;
    width: 250px;
}
.reset_position{
	position: absolute;
    top: 4px;
    z-index: 10;
    left: 3px;
}
.excess_fieldText_blinker{
	-webkit-animation: blinker 1s linear infinite;
	animation: blinker 1s linear infinite;
}
@keyframes blinker {
	50% {
	  opacity: 0;
	}
}


/***** common *****/

.no-margin{
	margin: 0px !important;
}
.no-padding{
	padding:0px !important
}
.max-width{
	max-width: 100%!important;
}
.preHeaderpad{
	padding: 15px 0px;
}
.commonHeaderpad{
	padding: 15px!important;
}
.headerTitle{
	font-size: 20px;
	color: #ffff;
}

/***** sidebar rewidth *****/

.sidebar-rewidth{
	width: 250px!important;
}
.main-panel{
	width: calc(100% - 94px)
}
.sidebar-wrapper-rewidth{
	width: 250px!important;
}
.sidebar-mini .sidebar-rewidth{
	width: 84px!important;
}

.sidebar-mini .sidebar-wrapper-rewidth{
	width: 84px!important;
}
.sidebar-mini .sidebar:hover.sidebar-rewidth{
	width: 250px!important;
}
.sidebar-mini .sidebar:hover .sidebar-wrapper-rewidth{
	width: 250px!important;
}
.sidebar-normal{
	padding-left: 10px;
}

/***** home page *****/

.collectionPro_actBtn{
	padding: 5px!important;
}
.countREL {
	position: relative;
}
.countABS {
	height: 18px;
    position: absolute;
    top: 5px;
    right: -10px;
    width: 18px;
    border-radius: 50%;
    background-color: blue;
    text-align: center;
}
.countABS label{
	margin-bottom: 0px !important;
}
.containerTopmargin{
	margin-top: 25px!important;
}

/***** customer view screen *****/

.statusStyle{
	color: #17a2b8 !important;
    font-size: 16px;
    letter-spacing: 1px;
	cursor: default;
}
.tooltip-inner{
	background-color: #fff!important;
	color: #000!important;
	padding: 5px 10px!important;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: #fff !important;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color: #fff !important;
}
.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
    border-left-color: #fff !important;
}
.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
    border-right-color: #fff !important;
}
.btn-std{
	padding: 5px!important;	
}
.btn-marLeft{
	margin: 0px 5px!important;
}
.btn{
	margin-top: 0px!important;
}
.recordAuditbox .ReactTable{
	padding: 15px;
}
.statusLabelRel{
	position: relative;
}
.verticalAlign{
	display: flex;
	flex-direction: row;
	align-items: center;
}
.custVisit_link{
	color: #fff!important;
	text-decoration: revert!important;
	padding: 0px 5px;
}
.custVisit_link:hover{
	color: lightgray!important;
}
.round_Img{
	border-radius: 50%;
}
.viewCus_label{
	font-size: 10px;
    color: #b0b7cd!important;
    font-weight: 500;
    padding-top: 23px;
	text-transform: uppercase;
}
.viewCus_labelVal{
	font-size: 13px;
    color: #fff!important;
    font-weight: 500;    
}
.image_rotate{
	height: 337px !important;
    width: 100% !important;
}
.for_width-100{
	width: 100%;
	height: 100%;
}

.image_rotate_res{
	height: 337px !important;
    width: 337px !important;
}
.rotate_icon{
display: none;
	position: absolute;
	color: #000;
	z-index: 7;
	box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.25);
	opacity: 1;
	right: 0;
}

.image_hover:hover .rotate_my_icon{
	display: flex !important;
	position: absolute;
	top: 0;
	color: #000;
	z-index: 7;
	box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.25);
	opacity: 1;
}

.image_hover:hover  .hoverd_style{
    z-index: 6 !important;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 40%;
	background: linear-gradient(180deg, rgba(25, 25, 25, 0) 20%, #131212 100%);
	transform: matrix(1, 0, 0, -1, 0, 0);
}
.rotate_icon_margin{
	margin-left: -25px !important;
}
.background-none{
	background: none !important;
}
.cusVisiticon{
	font-size: 13px;
	margin-right: 5px;
	color: #b0b7cd;
}
.rmUpper_case{
	text-transform: uppercase;
}
.mapFull_width{
	width: 45%;
	height: 50vh;
}
.cusVisit_imgCen{
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
}
.viewCus_imglabel{
	font-size: 10px!important;
    color: #b0b7cd!important;
    font-weight: 500!important;
	text-transform: uppercase!important;
}
.mapModal_width .modal-dialog {
	margin:0 auto!important;
	max-width: 1140px!important;
}
.modal-backdrop{
	display: none;
}
.loan_select{
	width: 115px;
}
.float-advance-products .btn{
	min-width: 150px;
}

.mismatch-label-color{
	font-size: 15px;
	color: red!important;
	font-weight: 500; 
}
.match-label-color{
	font-size: 15px;
	color: green!important;
	font-weight: 501;
 }
 
.cs_table{
    margin-bottom: 100px;
}

#chart_padding{
	padding-right: 2px;
	padding-left: 2px ;
}

@media screen and (max-width: 992px) {
       
	.rotate_icon{
		left: 50% !important;
   }
}

.pointer-event-fa-upgrade:disabled{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: not-allowed;
	z-index: 2;
}
.fa.fa-times-circle{
	font-size: 20px;
}

.download_sheet:hover{
	text-decoration: underline;
}

/*ATL Locked accounts in wallet statement */
.locked-text {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.locked-text .locked-content {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -100px;
    transition: opacity 0.3s;
}

.locked-text:hover .locked-content {
    visibility: visible;
    opacity: 1;
}

a {
	text-decoration: none !important;
}



.react_common_table tr td a  , 
.react_common_table tr td p ,
 .react_common_table tr td  span ,
  .react_common_table tr td label,  .react_common_table tr td div ,.react_common_table tr td button ,  .react_common_table tr td div ,.react_common_table tr td, button,.react_common_table th {
    font-size: 12px !important;
}


.tab-container {
	display: flex;
	border-bottom: 2px solid gray;
}
  
.tab-item {
	padding: 10px 68px;
	cursor: pointer;
	color: white;
}

.tab-item.active {
	border-bottom: 2px solid white;
	color: white; 
	font-weight: bolder;
}

.loader {
	font-size: 25px;
	font-weight: bold;
	color: #ffffff;
	animation: blink 1.5s infinite;
	margin-top: -35px;
  }
  
@keyframes blink {
0% { opacity: 1; }
50% { opacity: 0.5; }
100% { opacity: 1; }
}
  
